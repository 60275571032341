import { Button, Result } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';

export const NoAccess = () => {
  return (
    <Result
      status={403}
      title="Zugriff verweigert"
      subTitle="Sie haben nicht die benötigten Zugriffsrechte."
      extra={
        <Button icon={<ReloadOutlined />} onClick={() => (window.location.href = '/')} type="primary">
          Applikation neu laden
        </Button>
      }
    />
  );
};
