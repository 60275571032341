import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

export interface AppVersionState {
  newVersionAvailable: boolean;
  setNewVersionAvailable: (newVersionAvailable: boolean) => void;
  modalSuppressed: boolean;
  setModalSuppressed: (dialogSuppressed: boolean) => void;
  bannerVisible: () => boolean;
}

export const useAppVersionStore = create<AppVersionState>()(
  devtools(
    (set, get) => ({
      newVersionAvailable: false,
      setNewVersionAvailable: newVersionAvailable => set({ newVersionAvailable }),
      modalSuppressed: false,
      setModalSuppressed: modalSuppressed => set({ modalSuppressed }),
      bannerVisible: () => get().newVersionAvailable && get().modalSuppressed,
    }),
    { name: 'app-version-store' }
  )
);
