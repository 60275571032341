import React, { useEffect, useState } from 'react';
import { Button, Flex, Input, Select, Table, theme, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useDebounce } from 'use-debounce';
import { Markable } from '../../components/Markable';
import { CheckOutlined, EditOutlined, InfoCircleOutlined, PlusCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { PageHeader } from '@ant-design/pro-components';
import { tableActionCell } from '../../styles/globalCss';
import { css } from '@emotion/css';
import { Account } from '../../generated/openapi/befunde-api';
import { UpdateAccountModal } from './accounts/UpdateAccountModal.tsx';
import { CreateAccountModal } from './accounts/CreateAccountModal.tsx';
import { useGetAllAccounts } from '../../api/account.queries.ts';
import { useGetUserInfo } from '../../api/user-info.queries.ts';
import { buildAuthzInfo } from '../../utils/helpers.ts';

type StateFilter = 'all' | 'active' | 'inactive';

const useAccountsClassNames = () => {
  const { token } = theme.useToken();
  return {
    pageWrapper: css`
      padding: ${token.paddingLG}px;
    `,
  };
};

export const Accounts: React.FC = () => {
  const { token } = theme.useToken();
  const styles = useAccountsClassNames();
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [editAccount, setEditAccount] = useState<Account | null>(null);
  const [search, setSearch] = useState<string | null>(null);
  const [debouncedSearch] = useDebounce(search, 250);
  const [filteredAccounts, setFilteredAccounts] = useState<Account[]>([]);
  const [stateFilter, setStateFilter] = useState<StateFilter>('all');

  const { data, isLoading, isFetching, refetch } = useGetAllAccounts();
  const { refetch: refetchUserInfo } = useGetUserInfo();

  useEffect(() => {
    let result =
      data?.items.filter(account => {
        if (debouncedSearch && debouncedSearch.length) {
          const searchValue = debouncedSearch.toLowerCase();
          return (
            account.name.toLowerCase().includes(searchValue) ||
            account.authz
              .map(it => it.labId.toLowerCase())
              .join(' ')
              .includes(searchValue) ||
            account.authz
              .flatMap(it => it.identifiers)
              .map(it => it.toLowerCase())
              .join(' ')
              .includes(searchValue)
          );
        }
        return true;
      }) ?? [];

    if (stateFilter !== 'all') {
      result = result.filter(doctor => (stateFilter === 'active' ? !doctor.disabled : doctor.disabled));
    }

    setFilteredAccounts(result);
  }, [debouncedSearch, data, stateFilter]);

  const columns: ColumnsType<Account> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      defaultSortOrder: 'ascend',
      width: 130,
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: value => <Markable tokens={debouncedSearch ?? ''}>{value}</Markable>,
    },
    {
      title: 'Aktiv',
      dataIndex: 'disabled',
      key: 'disabled',
      width: 70,
      sorter: (a, b) => Number(b.disabled) - Number(a.disabled),
      render: value => (!value ? <CheckOutlined /> : ''),
    },
    {
      title: 'Labors',
      dataIndex: 'authz',
      key: 'authz',
      ellipsis: true,
      width: 120,
      render: (_, record) => <Markable tokens={debouncedSearch ?? ''}>{buildAuthzInfo(record.authz)}</Markable>,
    },
    {
      title: '',
      key: 'actions',
      fixed: 'right',
      align: 'right',
      ellipsis: true,
      width: '50px',
      className: tableActionCell,
      render: (_, record) => <Button icon={<EditOutlined />} type="text" onClick={() => setEditAccount(record)} />,
    },
  ];

  return (
    <div className={styles.pageWrapper}>
      <PageHeader
        title="Accounts"
        extra={
          <Flex wrap gap={token.paddingMD} align="center">
            <Select
              style={{ width: '130px' }}
              onChange={setStateFilter}
              value={stateFilter}
              options={[
                {
                  label: 'Alle',
                  value: 'all',
                },
                {
                  label: 'Nur Aktive',
                  value: 'active',
                },
                {
                  label: 'Nur Inaktive',
                  value: 'inactive',
                },
              ]}
            />
            <Input
              autoFocus
              allowClear
              placeholder="Suche"
              value={search ?? ''}
              onChange={e => setSearch(e.target.value)}
              prefix={<SearchOutlined />}
              suffix={
                <Tooltip title="Suche nach Name, Laborkennung und Identifikator">
                  <InfoCircleOutlined />
                </Tooltip>
              }
              style={{ width: '250px' }}
            />
            <Button icon={<PlusCircleOutlined />} type="primary" onClick={() => setCreateModalOpen(true)}>
              Neuer Account
            </Button>
          </Flex>
        }
        style={{ padding: 0, paddingBottom: 'inherit' }}
      />
      <Table<Account>
        scroll={{ x: 'max-content' }}
        rowKey={record => record.id}
        sticky={true}
        size="middle"
        showSorterTooltip={false}
        dataSource={filteredAccounts}
        pagination={{
          showQuickJumper: true,
          showSizeChanger: true,
          showTotal: (total, range) => `${range[0]} bis ${range[1]} von ${total} Accounts`,
        }}
        loading={isLoading || isFetching}
        columns={columns}
      />
      <CreateAccountModal
        open={createModalOpen}
        onClose={() => {
          setCreateModalOpen(false);
          refetch();
        }}
      />
      <UpdateAccountModal
        account={editAccount}
        onClose={() => {
          setEditAccount(null);
          refetchUserInfo();
          refetch();
        }}
      />
    </div>
  );
};
