import { Button, Input, InputRef, Tag, theme, Tooltip } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import React, { FocusEvent, KeyboardEvent, useEffect, useRef, useState } from 'react';
import { css } from '@emotion/css';

export const EditableTagGroup: React.FC<{
  tags?: string[];
  onChange?: (tags: string[]) => void;
  addLabel?: string;
  inputWidth?: string;
  inputMaxLength?: number;
}> = ({ tags = [], onChange = () => {}, addLabel = 'Neuer Tag', inputWidth = '78px', inputMaxLength }) => {
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [editInputIndex, setEditInputIndex] = useState(-1);
  const [editInputValue, setEditInputValue] = useState('');
  const { token } = theme.useToken();

  const saveInputRef = useRef<InputRef>(null);
  const saveEditInputRef = useRef<InputRef>(null);

  useEffect(() => {
    if (inputVisible) {
      saveInputRef.current?.focus();
    }
  }, [inputVisible]);

  useEffect(() => {
    if (editInputIndex !== -1) {
      saveEditInputRef.current?.focus();
    }
  }, [editInputIndex]);

  const handleInputConfirm = (e: FocusEvent | KeyboardEvent) => {
    e.preventDefault();
    if (inputValue && tags.indexOf(inputValue) === -1) {
      onChange([...tags, inputValue]);
    }
    setInputVisible(e.type !== 'blur');
    setInputValue('');
  };

  const handleEditInputConfirm = () => {
    const newTags = [...tags];
    newTags[editInputIndex] = editInputValue;
    onChange(newTags);
    setEditInputIndex(-1);
    setEditInputValue('');
  };

  const tagInputStyle = css`
    width: ${inputWidth};
    margin-right: 8px;
    vertical-align: top;
  `;

  return (
    <>
      {tags.map((tag, index) => {
        if (editInputIndex === index) {
          return (
            <Input
              ref={saveEditInputRef}
              key={tag}
              className={tagInputStyle}
              maxLength={inputMaxLength}
              size="small"
              value={editInputValue}
              onChange={e => setEditInputValue(e.target.value)}
              onBlur={handleEditInputConfirm}
              onPressEnter={handleEditInputConfirm}
            />
          );
        }

        const isLongTag = tag.length > 20;

        const tagElem = (
          <Tag
            key={tag}
            className={css`
              margin-bottom: 5px;
              user-select: none;
            `}
            closable
            onClose={() => onChange(tags.filter(t => t !== tag))}
          >
            <span
              onDoubleClick={e => {
                setEditInputIndex(index);
                setEditInputValue(tag);
                e.preventDefault();
              }}
            >
              {isLongTag ? `${tag.slice(0, 20)}...` : tag}
            </span>
          </Tag>
        );
        return isLongTag ? (
          <Tooltip title={tag} key={tag}>
            {tagElem}
          </Tooltip>
        ) : (
          tagElem
        );
      })}
      {inputVisible && (
        <Input
          ref={saveInputRef}
          className={tagInputStyle}
          maxLength={inputMaxLength}
          size="small"
          value={inputValue}
          onChange={e => setInputValue(e.target.value)}
          onBlur={handleInputConfirm}
          onPressEnter={handleInputConfirm}
        />
      )}
      {!inputVisible && (
        <Button
          className={css`
            font-size: ${token.fontSizeSM}px;
          `}
          type="default"
          size="small"
          onClick={() => setInputVisible(true)}
          icon={<PlusOutlined />}
        >
          {addLabel}
        </Button>
      )}
    </>
  );
};
