import { theme } from 'antd';
import { css } from '@emotion/css';

export const useRequestRendererClassNames = () => {
  const { token } = theme.useToken();

  const subheaderClassName = css`
    color: ${token.colorTextDisabled};
    font-size: ${token.fontSizeSM}px;
    font-weight: bold;
    text-transform: uppercase;
  `;

  return {
    subheaderClassName,
  };
};
