import { FC, ReactNode, useEffect, useState } from 'react';
import io from 'socket.io-client';
import { SocketContext } from './SocketContext';
import { TypedSocket } from './types.ts';
import { loadUser } from '../oidcConfig.ts';

const socket: TypedSocket = io({
  path: window._env_.WS_URL,
  transports: ['websocket'],
  autoConnect: false,
  auth: cb => cb({ jwt: loadUser()?.access_token }),
});

export const SocketProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [isConnected, setConnected] = useState(false);

  useEffect(() => {
    socket.connect();

    socket.on('connect', () => {
      console.debug('Connected to socket');
      setConnected(true);
    });

    socket.on('disconnect', reason => {
      console.debug(`Disconnected from socket: ${reason}`);
      setConnected(false);
    });

    // triggered in case of invalid credentials. we need to manually reconnect
    socket.on('connect_error', err => {
      console.debug(`Disconnected from socket: ${err.message}`);
      setConnected(false);
      setTimeout(() => socket.connect(), 3000);
    });

    socket.on('error', err => {
      console.error('Socket Error: ', err.message);
    });

    socket.on('exception', err => {
      console.error('Socket Exception: ', err.message);
    });

    return () => {
      console.debug('Socket cleanup');
      socket.off('connect');
      socket.off('disconnect');
      socket.off('error');
      socket.off('exception');
      socket.disconnect();
    };
  }, []);

  return (
    <SocketContext.Provider value={{ socket: socket, isConnected: isConnected }}>{children}</SocketContext.Provider>
  );
};
