import { useEffect, useState } from 'react';
import { hasAuthParams, useAuth } from 'react-oidc-context';
import { currentUrlWithoutQueryString } from '../utils/helpers.ts';

export const useAuthEvents = () => {
  const auth = useAuth();
  const [hasTriedSignin, setHasTriedSignin] = useState(false);

  // automatically sign-in
  useEffect(() => {
    if (!hasAuthParams() && !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading && !hasTriedSignin) {
      auth.signinRedirect();
      setHasTriedSignin(true);
    }
  }, [auth, hasTriedSignin]);

  // handle renew errors, e.g. session timeouts
  useEffect(() => {
    return auth.events.addSilentRenewError(async () => {
      // no need to handle the error type or retries here, retry on timeout is handled by the lib
      // see https://github.com/authts/oidc-client-ts/blob/main/src/SilentRenewService.ts#L54C11-L54C11
      //console.log('silent renew error', JSON.stringify(e));
      await auth.removeUser();
      window.location.href = currentUrlWithoutQueryString();
    });
  }, [auth]);

  // multi-tab logout (enable via monitorSession: true)
  // useEffect(() => {
  //   return auth.events.addUserSignedOut(async () => {
  //     await auth.removeUser();
  //     window.location.href = currentUrlWithoutQueryString();
  //   });
  // }, [auth]);
};
