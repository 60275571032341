import {
  Configuration,
  LabResultApi,
  LabResultApiLabResultRequest,
  LabResultApiSearchLabResultsRequest,
  LabResultResponse,
  LabResultsSearchResponse,
} from '../generated/openapi/befunde-api';
import { loadUser } from '../oidcConfig.ts';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

const api = new LabResultApi(
  new Configuration({ accessToken: () => loadUser()?.access_token ?? '' }),
  window._env_.API_URL
);

export const useSearchLabResults = (params: LabResultApiSearchLabResultsRequest) =>
  useQuery<LabResultsSearchResponse, AxiosError>({
    queryKey: [api.searchLabResults.name, params],
    queryFn: async () => {
      const res = await api.searchLabResults(params);
      return res?.data;
    },
    retry: false,
  });

export const useLabResult = (params: LabResultApiLabResultRequest) =>
  useQuery<LabResultResponse, AxiosError>({
    queryKey: [api.labResult.name, params],
    queryFn: async () => {
      const res = await api.labResult(params);
      return res?.data;
    },
    retry: false,
    enabled: !!params.id,
  });
