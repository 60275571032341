import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import { DisconnectOutlined } from '@ant-design/icons';

export const OfflineBlockingModal: React.FC = () => {
  const [online, setOnline] = useState(navigator.onLine);

  useEffect(() => {
    const listener = () => {
      if (!navigator) {
        setOnline(true);
        return;
      }
      setOnline(navigator.onLine);
    };

    window.addEventListener('offline', listener);
    window.addEventListener('online', listener);

    return () => {
      window.removeEventListener('offline', listener);
      window.removeEventListener('online', listener);
    };
  }, []);

  return (
    <Modal
      title={
        <span>
          <DisconnectOutlined /> Keine Internetverbindung
        </span>
      }
      open={!online}
      footer={null}
      closable={false}
    >
      Sie scheinen offline zu sein. Die Anwendung benötigt eine aktive Verbindung zum Internet.
      <br />
      Dieser Dialog verschwindet automatisch, sobald Sie wieder online sind.
    </Modal>
  );
};
