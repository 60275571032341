import React from 'react';
import { Spin } from 'antd';

export const LoadingIndicator: React.FC<{ height?: string }> = ({ height = '100%' }) => {
  return (
    <div
      style={{
        height: height,
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Spin size="large" />
    </div>
  );
};
