import React from 'react';
import { FallbackProps } from 'react-error-boundary';
import { Button, Input, Result } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';

export const ErrorFallback: React.FC<FallbackProps> = ({ error }: FallbackProps) => {
  return (
    <div style={{ maxWidth: '800px', marginLeft: 'auto', marginRight: 'auto' }}>
      <Result
        status={500}
        title="Es tut uns leid, etwas ist schiefgelaufen"
        subTitle={
          <div>
            Laden Sie die Applikation neu, um Ihre Arbeit fortzusetzen.
            <br />
            Sie können unseren Support auch gerne unter{' '}
            <a rel="noopener noreferrer" href="mailto:support@medcom.at">
              support@medcom.at
            </a>{' '}
            bzw.{' '}
            <a rel="noopener noreferrer" href="tel:+4318693658">
              +43 1 8693658
            </a>{' '}
            kontaktieren.
            <p />
            <h3>Fehlerdetails</h3>
            <Input.TextArea disabled rows={3} value={error.message} />
          </div>
        }
        extra={
          <Button icon={<ReloadOutlined />} onClick={() => (window.location.href = '/')} type="primary">
            Applikation neu laden
          </Button>
        }
      />
    </div>
  );
};
