import dayjs from 'dayjs';

export const SHORT_DATETIME_FORMAT = 'DD.MM.YYYY, HH:mm';
export const DATE_FORMAT = 'DD.MM.YYYY';

export const formatDateString = (value: string | null | undefined) => {
  return value ? dayjs(value).format(DATE_FORMAT) : null;
};
export const formatDateTimeString = (value: string | null | undefined) => {
  return value ? `${dayjs(value).format(SHORT_DATETIME_FORMAT)} Uhr` : null;
};
