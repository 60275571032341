import React, { useEffect } from 'react';
import { App, Button, Modal } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useForm } from 'antd/es/form/Form';
import { Account } from '../../../generated/openapi/befunde-api';
import { useUpdateAccount } from '../../../api/account.queries.ts';
import { AccountForm, FormModel } from './AccountForm.tsx';

export const UpdateAccountModal: React.FC<{
  onClose: () => void;
  account: Account | null;
}> = ({ onClose, account }) => {
  const { mutate, isPending } = useUpdateAccount();
  const [form] = useForm<FormModel>();
  const { message } = App.useApp();

  const update = async (values: FormModel) => {
    mutate(
      {
        id: account!.id,
        accountCreateOrUpdateRequest: values,
      },
      {
        onSuccess: () => {
          message.success('Account wurde aktualisiert');
          closeAndReset();
        },
        onError: e => {
          message.error('Account konnte nicht aktualisiert werden: ' + e);
        },
      }
    );
  };

  const closeAndReset = () => {
    form.resetFields();
    onClose();
  };

  useEffect(() => {
    form.setFieldsValue({
      name: account?.name || '',
      disabled: account?.disabled ?? false,
      authz: account?.authz ?? [],
    });
  }, [form, account]);

  return (
    <Modal
      width={600}
      title="Account bearbeiten"
      open={!!account}
      destroyOnClose
      onCancel={closeAndReset}
      forceRender
      footer={[
        <Button key="cancel" onClick={closeAndReset}>
          Abbrechen
        </Button>,
        <Button key="submit" icon={<EditOutlined />} type="primary" loading={isPending} onClick={form.submit}>
          Speichern
        </Button>,
      ]}
    >
      <AccountForm form={form} onFinish={update} />
    </Modal>
  );
};
