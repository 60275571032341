import { FC } from 'react';
import { Descriptions, Space } from 'antd';
import { DesktopOutlined, MailOutlined, PhoneOutlined, ReadOutlined } from '@ant-design/icons';

export const HelpSupport: FC = () => {
  return (
    <Descriptions column={1} size="small" bordered>
      <Descriptions.Item
        label={
          <Space>
            <ReadOutlined /> Handbuch
          </Space>
        }
      >
        <a href="https://docs.medcom.at/labcomplete-handbuch-current.pdf" target="_blank" rel="noopener noreferrer">
          Handbuch öffnen
        </a>
      </Descriptions.Item>
      <Descriptions.Item
        label={
          <Space>
            <PhoneOutlined /> Hotline
          </Space>
        }
      >
        <a href="tel:+4318693658">+43 1 869 36 58</a>
      </Descriptions.Item>
      <Descriptions.Item
        label={
          <Space>
            <MailOutlined /> E-Mail
          </Space>
        }
      >
        <a href="mailto:support@medcom.at">support@medcom.at</a>
      </Descriptions.Item>
      <Descriptions.Item
        label={
          <Space>
            <DesktopOutlined /> Fernwartung
          </Space>
        }
      >
        <a target="_blank" rel="noopener noreferrer" href="https://get.teamviewer.com/de/6a9ydch">
          Download TeamViewer
        </a>
      </Descriptions.Item>
    </Descriptions>
  );
};
