import { useEffect } from 'react';

export const useKeyAction = (key: string, action: null | (() => void)) => {
  useEffect(() => {
    if (action) {
      const keyDownHandler = (e: globalThis.KeyboardEvent) => {
        if (e.key === key) {
          e.preventDefault();
          action();
        }
      };
      window.addEventListener('keydown', keyDownHandler);

      return () => {
        window.removeEventListener('keydown', keyDownHandler);
      };
    }
  }, [key, action]);
};
