import { Flex } from 'antd';
import React, { useState } from 'react';
import { ObservationResultsRenderer } from './observationresult/ObservationResultsRenderer.tsx';
import { RequestRenderer } from './request/RequestRenderer.tsx';
import { SpecimenRenderer } from './specimen/SpecimenRenderer.tsx';
import { useGridClassNames } from './OrderResult.styles.ts';
import { css } from '@emotion/css';
import { emptyFilter, Filter } from './filter/FilterTypes.ts';
import { ObservationResultFilter } from './filter/ObservationResultFilter.tsx';
import { LabResultResponse } from '../../generated/openapi/befunde-api';

const useOrderResultRendererClassNames = () => {
  return {
    wrapper: css`
      overflow-y: auto;
    `,
  };
};

export const OrderResultRenderer: React.FC<{
  result: LabResultResponse;
  deemphasizePathological: boolean;
}> = ({ result, deemphasizePathological }) => {
  const { detailsClassName } = useGridClassNames();
  const { wrapper } = useOrderResultRendererClassNames();
  const [filter, setFilter] = useState<Filter>(emptyFilter);

  return (
    <Flex vertical className={detailsClassName}>
      <RequestRenderer result={result} />
      <ObservationResultFilter value={filter} onChange={setFilter} />
      <div className={wrapper}>
        <SpecimenRenderer result={result} />
        <ObservationResultsRenderer result={result} filter={filter} deemphasizePathological={deemphasizePathological} />
      </div>
    </Flex>
  );
};
